/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Button, Card } from "react-dsgov";
import AriaService from "../../../services/AriaService";
import IPortaria from "./IPortaria";
import useFiltroDetalheLeilaoState from "../../../state/hooks/useFiltroDetalheLeilaoState";
import DateUtil from "../../../util/DateUtil";

const PortariasDia: React.FC = () => {
    const [portarias, setPortarias] = useState<IPortaria[]>();
    const [filtroDetalheLeilao, setFiltroDetalheLeilao] = useFiltroDetalheLeilaoState();
    const dateUtil = new DateUtil();
    const [linkCalendarioImpressao, setLinkCalendarioImpressao] = useState<string>();

    const defineFiltroData = (data?: string) => {
        setFiltroDetalheLeilao(filtroDetalheLeilaoAnt => {
            return { ...filtroDetalheLeilaoAnt, data: dateUtil.formataDataTypeScript(data) };
        })
    }

    useEffect(() => {
        const ariaService = new AriaService();

        ariaService.getUrlCalendarioImpressao().then((link) => setLinkCalendarioImpressao(link));
        ariaService.getPortariasDia().then(portarias => {
            setPortarias(portarias)
        });

    }, []);



    const abrirLinkCalendarioImpressao = () => {
        if (linkCalendarioImpressao) {
            window.location.href = linkCalendarioImpressao
        }
    }


    return (
        <Card className="card-painel" style={{ height: "100%", position: "relative" }}>
            <Card.Header cardTitle={"Editais da Semana"} />
            <Card.Content >
                {portarias && portarias.length > 0 &&
                    <ul>
                        {portarias?.map(portaria =>
                            <li key={portaria.id}>
                                <a href={portaria.link} target="_blank"><i className="fas fa-download"></i> {portaria.nome}</a> {portaria.linkResultado && <>&nbsp;-&nbsp;<a href={portaria.linkResultado} target="_blank"> <i className="fas fa-search"></i> Resultado </a></>}
                            </li>
                        )}
                    </ul>}
                {(!portarias || portarias.length === 0) && <>Sem editais divulgados.</>}
            </Card.Content>
            <Card.Footer style={{minHeight: "50px", position: "absolute", bottom: -40, right: 0}}>
                <Button
                    style={{float: "right", minHeight: "50px"}}
                    onClick={() => window.open("https://www.tesourotransparente.gov.br/consultas/portarias-resultados-e-comunicados-dos-leiloes-da-divida-publica-federal")}
                    primary
                ><i className="fas fa-search"></i> Histórico Editais</Button>
            </Card.Footer>
        </Card>
    )
}

export default PortariasDia;