import { useEffect, useState } from "react";
import { Button, Table } from "react-dsgov";
import AriaService from "../../../services/AriaService";
import useFiltroDetalheLeilaoState from "../../../state/hooks/useFiltroDetalheLeilaoState";
import DateUtil from "../../../util/DateUtil";
import IResultadoLeilao from "./IResultadoLeilao";

const ResultadoLeilao : React.FC = () => {
    const [resultadosLeilao, setResultadosLeilao] = useState<IResultadoLeilao[]>();
    const [filtroDetalheLeilao, setFiltroDetalheLeilao] = useFiltroDetalheLeilaoState();

    const dateUtil = new DateUtil();

    const defineFiltroData = (data? : string, benchmark? : string) => {
        setFiltroDetalheLeilao(filtroDetalheLeilaoAnt => {
            return {...filtroDetalheLeilaoAnt, data: dateUtil.formataDataTypeScript(data), benchmark: benchmark};
        })
    }

    useEffect(() => {
        const ariaService = new AriaService();

        ariaService.getResultadosLeilao().then((resultados) => setResultadosLeilao(resultados))
    }, []);

    return (
        <div>
            {resultadosLeilao && resultadosLeilao.length > 0 && 
            <>
                <h4>Resultado do Leilão</h4>
                <Table
                    className="tabela-resultado-leilao"
                    title="Resultado do Leilão"
                    showPageSelector={false}
                    showPagination={false}
                    showDensityButtons={false}
                    showSearch={false}
                    showSelectedBar={false}
                    showTopBar={false}
                >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Data</th>
                            <th>Tipo</th>
                            <th>Benchmark</th>
                            <th>Vencimento</th>
                            <th>Quantidade Ofertada</th>
                            <th>Taxa de Corte</th>
                            <th>Quantidade Aceita (Mercado)</th>
                            <th>Financeiro Aceito (Mercado)</th>                            
                            <th>Portaria</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultadosLeilao?.map((resultadoLeilao) => 
                            <tr key={resultadoLeilao.data}>
                                <td><Button circle
                        className="botao-abrir-modal-detalhe"
                        primary
                        small
                        onClick={() => defineFiltroData(resultadoLeilao.data, resultadoLeilao.benchmark)}  
                    ><i className="fas fa-search"></i></Button></td>
                                <td><span className="header-mobile">Data: </span>{dateUtil.formataDataTypeScript(resultadoLeilao.data)}</td>
                                <td><span className="header-mobile">Tipo: </span>{resultadoLeilao.tipo}</td>
                                <td><span className="header-mobile">Benchmark: </span>{resultadoLeilao.benchmark}</td>
                                <td><span className="header-mobile">Vencimento: </span>{dateUtil.formataDataTypeScript(resultadoLeilao.dataVencimento)}</td>
                                <td><span className="header-mobile">Oferta: </span>{resultadoLeilao.oferta ? resultadoLeilao.oferta.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "-"}</td>
                                <td><span className="header-mobile">Taxa de corte: </span>{resultadoLeilao.taxa.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 10 })}</td>
                                <td><span className="header-mobile">Quantidade: </span>{resultadoLeilao.qtdAceita.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                                <td><span className="header-mobile">Financeiro Aceito: </span>{resultadoLeilao.financeiroAceito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>                                
                                <td><span className="header-mobile">Portaria: </span>{resultadoLeilao.nroPortaria}</td>
                                
                            </tr>)}
                    </tbody>

                </Table>
            </>
            }
        </div>
    );
}

export default ResultadoLeilao;