export default class ColorUtil {
    public darkenColor(hexColor: string, percent: number): string {
        // Converte a cor hexadecimal em um array de números de 0 a 255 para cada canal de cor (R, G e B)
        const hexToRgb = (hexColor: string): [number, number, number] => {
          const hex = hexColor.replace(/^#/, '');
          const r = parseInt(hex.substring(0, 2), 16);
          const g = parseInt(hex.substring(2, 4), 16);
          const b = parseInt(hex.substring(4, 6), 16);
          return [r, g, b];
        };
      
        // Converte um número de 0 a 255 em uma string hexadecimal de dois caracteres
        const decimalToHex = (decimal: number): string => {
          const hex = decimal.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };
      
        // Escurece uma cor RGB em um determinado percentual
        const darkenRgb = (rgb: [number, number, number], percent: number): [number, number, number] => {
          const [r, g, b] = rgb;
          const delta = Math.round(255 * (percent / 100));
          return [Math.max(r - delta, 0), Math.max(g - delta, 0), Math.max(b - delta, 0)];
        };
      
        // Escurece a cor hexadecimal em um determinado percentual
        const rgbColor = hexToRgb(hexColor);
        const darkenedRgbColor = darkenRgb(rgbColor, percent);
        const darkenedHexColor = `#${decimalToHex(darkenedRgbColor[0])}${decimalToHex(darkenedRgbColor[1])}${decimalToHex(darkenedRgbColor[2])}`;
        return darkenedHexColor;
      }
}