export default class DateUtil {

    public formataDataTypeScript(dateString?: string) : string | undefined {
        if(!dateString) {
            return undefined;
        }
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    }
}