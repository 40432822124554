import { Modal, Tab, Table } from "react-dsgov";
import useFiltroDetalheLeilaoState from "../../../state/hooks/useFiltroDetalheLeilaoState";
import { useEffect, useState } from "react";
import AriaService from "../../../services/AriaService";
import IBenchmarkOfertado from "./IBenchmarkOfertado";
import DateUtil from "../../../util/DateUtil";
import TaxaCorteDetalhe from "./TaxaCorteDetalhe/TaxaCorteDetalhe";

const DetalheResultado : React.FC = () => {
    const [filtroDetalheLeilao, setFiltroDetalheLeilao] = useFiltroDetalheLeilaoState();
    const [benchmarksOfertados, setBenchmarksOfertados] = useState<IBenchmarkOfertado[]>([]);
    const [abaAtual, setAbaAtual] = useState<number>(0);
    const dateUtil = new DateUtil();

    useEffect(() => {
        const ariaService = new AriaService();

        if(filtroDetalheLeilao?.data && filtroDetalheLeilao?.benchmark) {
            ariaService.getBenchmarksOftertados(filtroDetalheLeilao.data).then((benchmarksOfertados) => { 
                // Definindo a lista de benchmarks
                setBenchmarksOfertados(benchmarksOfertados);
                
                let index = 0;
                for(const benchmarkOfertado of benchmarksOfertados) {
                    if(benchmarkOfertado.benchmark === filtroDetalheLeilao.benchmark) {
                        setAbaAtual(index+1);
                    }
                    index++;
                }
            });
        } else {
            setBenchmarksOfertados([]);
        }
        
    }, [filtroDetalheLeilao]);

    const limpaFiltroDetalhe = () => {
        setFiltroDetalheLeilao({});
    }

    return (
        <div>            
            <Modal 
                useScrim
                className="modal-resultado-leilao"
                modalOpened={filtroDetalheLeilao.data !== undefined}
                onCloseButtonClick={() => limpaFiltroDetalhe()}
                title="Detalhe do Resultado"
                showCloseButton={true}>
                {!benchmarksOfertados && <span>Carregando...</span>}
                
                <Tab className="metade-escala" current={abaAtual} onTabChange={(current) => setAbaAtual(current)}>
                    
                    {filtroDetalheLeilao?.data && benchmarksOfertados && benchmarksOfertados.map((benchmarkOfertado) => 
                        <Tab.Content key={benchmarkOfertado.benchmark} title={benchmarkOfertado.benchmark}>
                            <Table 
                                title="Resultado do Leilão"
                                showPageSelector={false}
                                showPagination={false}
                                showDensityButtons={false}
                                showSearch={false}
                                showSelectedBar={false}
                                showTopBar={false}
                            >
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Título</th>
                                        <th>Vencimento</th>
                                        <th>Cupom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {benchmarkOfertado.valores && benchmarkOfertado.valores.map((valor) => 
                                    <tr key={dateUtil.formataDataTypeScript(valor.data)}>
                                        <td><span className="header-mobile">Data: </span>{dateUtil.formataDataTypeScript(valor.data)}</td>
                                        <td><span className="header-mobile">Título: </span>{valor.titulo}</td>
                                        <td><span className="header-mobile">Vencimento: </span>{dateUtil.formataDataTypeScript(valor.dataVencimento)}</td>
                                        <td><span className="header-mobile">Cupom: </span>{valor.cupom}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                            
                            <h4 style={{textAlign: "center"}}>Taxas de corte nos últimos 5 leilões</h4>
                            <TaxaCorteDetalhe benchmarkOfertado={benchmarkOfertado} />
                        </Tab.Content>
                    )}
                </Tab>
                
            </Modal>



        </div>
    )
}

export default DetalheResultado;