import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import IBenchmarkOfertado from "../IBenchmarkOfertado";
import DateUtil from "../../../../util/DateUtil";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export interface ITaxaCorteDetalheProps {
    benchmarkOfertado : IBenchmarkOfertado
}

const TaxaCorteDetalhe : React.FC<ITaxaCorteDetalheProps> = ({benchmarkOfertado}) => {
    const dateUtil = new DateUtil();


    const [dataGrafico, setDataGrafico] = useState<{
        labels: string[];
        datasets: any[];
    }>();

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom' as const,
          }
        },
      };

    useEffect(() => {
        const dadosGrafico = benchmarkOfertado.dadosGrafico.slice(0, 5);

        // Constrói os arrays
        const anos : string[] = [];
        const taxasAceitas : number[] = [];
        const taxasMedias : number[] = [];
        

        for(const dadoGrafico of dadosGrafico) {
            const dataFormatada = dateUtil.formataDataTypeScript(dadoGrafico.data);

            if(dataFormatada) {
                anos.push(dataFormatada);
                taxasAceitas.push(dadoGrafico.taxaAceita);
                taxasMedias.push(dadoGrafico.taxaMedia);
            }
        }

        // Constrói os datasets
        const datasets : any[] = [];

        datasets.push({
            label: "Taxas Aceitas",
            data: taxasAceitas.reverse(),
            backgroundColor: "#3366CC",
            borderColor: "#3366CC"

        });

        datasets.push({
            label: "Taxas Médias",
            data: taxasMedias.reverse(),
            backgroundColor: "#DC3912",
            borderColor: "#DC3912"
        });
        

        const dataGrafico = {
            labels: anos.reverse(),
            datasets: datasets
        };

        setDataGrafico(dataGrafico);


    }, [benchmarkOfertado]);

    return (
        <>
            {dataGrafico && <Line options={options} data={dataGrafico} />}
        </>
    
    );
}

export default TaxaCorteDetalhe;