import { useEffect, useState } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import AriaService from "../../../../services/AriaService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export interface ITaxaCorteProps {
    titulos: string[],
    cabecalho: string
}

const TaxaCorte : React.FC<ITaxaCorteProps> = ({titulos, cabecalho}) => {

    const [dados, setDados] = useState<{
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor?: string | undefined;
            borderColor?: string | undefined;
        }[];
    }>();

    useEffect(() => {
        const ariaService = new AriaService();
        ariaService.getTaxasCorte(titulos).then((dadosTaxaCorte => {
            // Montar datasets
            const datasetsGrafico : {
                label: string;
                data: number[];
                backgroundColor?: string;
                borderColor?: string | undefined;
            }[] = [];


            let indexBenchmark = 0;
            for(const benchmark of dadosTaxaCorte.benchmarks) {
                const valoresBenchmark : number[] = [];
                for(const mesAno of dadosTaxaCorte.datas) {
                    const valor = benchmark.valores[mesAno];
                    valoresBenchmark.push(valor);
                }

                datasetsGrafico.push({
                    label: benchmark.nome,
                    data: valoresBenchmark,
                    backgroundColor: benchmark.cor,
                    borderColor: benchmark.cor
                });
                indexBenchmark++;
            }

            setDados({
                labels: dadosTaxaCorte.datas,
                datasets: datasetsGrafico
            });
        }));
    }, [titulos]);

    const options = {
        maintainAspectRatio: false,
        spanGaps: true,
        plugins: {
            legend: {
            position: 'bottom' as const,
            }
        },
    };

    return (
        <>
            <h4 style={{textAlign: "center"}}>{cabecalho}</h4>
            <div>
                {dados && <Line options={options} data={dados} height={440}  />}
            </div>
            
        </>
        
    );
}

export default TaxaCorte;