import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import AriaService from "../../../../services/AriaService";
import ColorUtil from "../../../../util/ColorUtil";

ChartJS.register(ArcElement, Tooltip, Legend);


const EmissoesIndexacao : React.FC = () => {
    const [emissoesIndexacao, setEmissoesIndexacao] = useState<any>();

    const colors : any = {
         "LFT":"#0070c0",
         "LTN":"#00b050",
         "NTN-B":"#ffc000",
         "NTN-F":"#92d050"
    };

    useEffect(() => {
        const ariaService = new AriaService();
        const colorUtil = new ColorUtil();
        ariaService.getTitulosIndexador().then((titulosIndexacao => {
            const titulos : string[] = [];
            const valores : number[] = [];
            const cores : string[] = [];
            const coresMaisEscuras : string[] = [];

            for(const titulo of titulosIndexacao) {
                titulos.push(titulo.titulo);
                valores.push(titulo.financeiroAceito);
                cores.push(colors[titulo.titulo]);
                coresMaisEscuras.push(colorUtil.darkenColor(colors[titulo.titulo], 10));

            }

            const dadosGrafico = {
                labels: titulos,
                datasets: [
                  {
                    label: 'Financeiro Aceito',
                    data: valores,
                    backgroundColor: cores,
                    borderColor: coresMaisEscuras,
                    borderWidth: 1,
                  },
                ],
              };

            setEmissoesIndexacao(dadosGrafico);
        }));
    }, []);

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
            }
        },
    };
    
    return (
        <>
            <h4 style={{textAlign: "center"}}>Emissões por Título - 12 Meses</h4>
            <div>
                {emissoesIndexacao && <Pie height={440} options={options} data={emissoesIndexacao} />}
            </div>
        </>
        
    );
}

export default EmissoesIndexacao;