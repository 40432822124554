import React from 'react';
import logo from './logo.svg';
import './App.css';
import SiteLeiloes from './pages/SiteLeiloes/SiteLeiloes';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot>
      <SiteLeiloes />
    </RecoilRoot>    
  );
}

export default App;
