import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import AriaService from "../../../../services/AriaService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const VolumeEmitido : React.FC = () => {
    const colors : any = {
        "LFT":"#0070c0",
        "LTN":"#00b050",
        "NTN-B":"#ffc000",
        "NTN-F":"#92d050"
   };

    const [dadosGrafico, setDadosGrafico] = useState<{
        labels: string[];
        datasets: {
            label: string;
            data: any[];
            backgroundColor: string;
        }[];
    }>();


    useEffect(() => {
        const ariaService = new AriaService();

        ariaService.getVolumesBenchmark().then(volumes => {
            // Preparando os dados para o gráfico
            const labels : string[] = [];
            const benchmarks : string[] = [];
            
            const valores : Record<string, number> = {};

            // Identifica as labels (anoMes) e os benchmarks
            for(const volume of volumes) {
                if(labels.indexOf(volume.anoMes) == -1) {
                    labels.push(volume.anoMes);
                }

                if(benchmarks.indexOf(volume.nomeBenchmark) == -1) {
                    benchmarks.push(volume.nomeBenchmark);
                }

                valores[`${volume.anoMes}|${volume.nomeBenchmark}`] = volume.valorAceito;

            }

            const data : any = {
                labels,
                datasets: []
            };

            // Percorre os benchmarks e as labels, e vai preenchendo os dados no formato
            let indexBenchmark = 0;
            for(const benchmark of benchmarks) {
                const valoresBenchMarkAnoMes : number[] = [];

                for(const anoMes of labels) {
                    const valor = valores[`${anoMes}|${benchmark}`];
                    valoresBenchMarkAnoMes.push(valor);
                }

                data.datasets.push({
                    label: benchmark,
                    data: valoresBenchMarkAnoMes,
                    backgroundColor: colors[benchmark],
                });   
                
                indexBenchmark++;
            }

            setDadosGrafico(data);
        });
    }, []);

   
   const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
            }
        },
        scales: {
            x: {
            stacked: true,
            },
            y: {
            stacked: true,
            },
        },
    };
  
  
  
  
  

    return (
        <>
            <h4 style={{textAlign: "center"}}>Volume Emitido - 12 Meses</h4>
            <div>
                {dadosGrafico && <Bar options={options} data={dadosGrafico} height={440} />}
            </div>
        </>
        
        
    );
}

export default VolumeEmitido;