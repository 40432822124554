import { useEffect, useState } from "react";
import { Button, Card } from "react-dsgov";
import AriaService from "../../../services/AriaService";
import IComunicado from "./IComunicado";

const Comunicados : React.FC = () => {
    const [comunicados, setComunicados] = useState<IComunicado[]>();

    useEffect(() => {
        const ariaService = new AriaService();

        ariaService.getComunicados().then((comunicados) => setComunicados(comunicados))
    }, []);

    return (
        <Card className="card-painel" style={{height: "100%", position: "relative"}}>
            <Card.Header cardTitle="Comunicados" />
            <Card.Content >
                <ul  style={{fontSize: "13px"}}>
                    {comunicados?.map(comunicado => 
                        <li key={comunicado.id} >
                            <a href={comunicado.link} target="_blank">{comunicado.nome}</a>
                        </li>
                    )}
                </ul>
            </Card.Content>
            <Card.Footer style={{minHeight: "50px", position: "absolute", bottom: -40, right: 0}}>
                <Button 
                    style={{float: "right", minHeight: "50px"}}
                    primary
                    
                    onClick={() => window.open("https://www.tesourotransparente.gov.br/consultas/portarias-resultados-e-comunicados-dos-leiloes-da-divida-publica-federal")}  
                ><i className="fas fa-search"></i> Comunicados anteriores</Button>
            </Card.Footer>
        </Card>
    );
}

export default Comunicados;