import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-dsgov";
import AriaService from "../../../services/AriaService";
import EmissoesIndexacao from "./EmissoesIndexacao/EmissoesIndexacao";
import ITituloIndexador from "./EmissoesIndexacao/ITituloIndexador";
import TaxaCorte from "./TaxaCorte/TaxaCorte";
import VolumeEmitido from "./VolumeEmitido/VolumeEmitido";


const Graficos : React.FC = () => {
    const titulosPrefixado = useMemo(() => ["LTN", "NTN-F"], []) ;
    const titulosIndicePrecos = useMemo(() => ["NTN-B"], []) ;
    const titulosTaxaFlutuante = useMemo(() => ["LFT"], []);

    return (
        <>
            <Row>
                <Col sm={12} md={4}>
                    <TaxaCorte titulos={titulosPrefixado} cabecalho="Taxa de Corte - Prefixado" />
                </Col>
                <Col sm={12} md={4}>
                    <TaxaCorte titulos={titulosIndicePrecos}  cabecalho="Taxa de Corte - Índice de Preços"  />
                </Col>
                <Col sm={12} md={4}>
                    <TaxaCorte titulos={titulosTaxaFlutuante} cabecalho="Taxa de Corte - Taxa Flutuante" />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={4}>
                    <EmissoesIndexacao />
                </Col>
                <Col sm={12} md={8}>
                    <VolumeEmitido />
                </Col>
            </Row>
            
            
        </>
        
    );
}

export default Graficos;