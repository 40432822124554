import { Button, Card, Col, Container, Row } from "react-dsgov";
import Comunicados from "./Comunicados/Comunicados";
import Graficos from "./Graficos/Graficos";
import PortariasDia from "./PortariasDia/PortariasDia";
import ProximosLeiloes from "./ProximosLeiloes/ProximosLeiloes";
import ResultadoLeilao from "./ResultadoLeilao/ResultadoLeilao";
import DetalheResultado from "./DetalheResultado/DetalheResultado";

import "./site.css";
import { useEffect, useState } from "react";
import AriaService from "../../services/AriaService";

const SiteLeiloes: React.FC = () => {
    const [linkCalendarioImpressao, setLinkCalendarioImpressao] = useState<string>();

    useEffect(() => {
        const ariaService = new AriaService();

        ariaService.getUrlCalendarioImpressao().then((link) => setLinkCalendarioImpressao(link));
    }, [])

    const abrirLinkCalendarioImpressao = () => {
        if (linkCalendarioImpressao) {
            window.location.href = linkCalendarioImpressao
        }
    }

    return (

        <Container fluid>
            <Row style={{ marginBottom: "10px" }}>
                <Col>                    
                    <Button style={{ float: 'right', right:'-10px' }}
                        disabled={!linkCalendarioImpressao}
                        onClick={() => window.open("https://www.tesourotransparente.gov.br/publicacoes/plano-anual-de-financiamento-paf")}                        
                        primary><i className="fas fa-file"></i>&nbsp;Planejamento</Button><span></span>
                    <Button style={{ float: 'right', right:'-5px' }}
                        disabled={!linkCalendarioImpressao}
                        onClick={abrirLinkCalendarioImpressao}
                        primary><i className="fas fa-print"></i>&nbsp;Calendário (Impressão)</Button><span></span>                    
                    <Button style={{ float: 'right' }}
                        onClick={() => window.open("https://sisweb.tesouro.gov.br/apex/f?p=10250:7:111887894361883::NO:RP,7:P7_ID_PROJETO:461")}
                        primary><i className="fas fa-code"></i>&nbsp;API dos Leilões</Button> 
                        <Button style={{ float: 'right', right: '5px' }}
                        onClick={() => window.open("https://sisweb.tesouro.gov.br/apex/f?p=2691:2")}
                        primary><i className="fas fa-search"></i>&nbsp;Leilões anteriores</Button>
                </Col>
            </Row>


            <Row style={{ display: "flex" }}>
                <Col sm={12} md={6}>
                    <PortariasDia />
                </Col>
                <Col sm={12} md={6}>
                    <Comunicados />
                </Col>
            </Row>
            <Row>
                <Col>
                  &nbsp;  
                </Col>
            </Row>
            <Row>
                <Col >
                    <ResultadoLeilao />                    
                </Col>
            </Row>
            <Row>
                <Col>
                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <Graficos />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header cardTitle='Histórico e outras informações' cardSubtitle=''></Card.Header>
                        <Card.Content>
                            <p>O histórico dos leilões pode ser consultado por múltiplas formas:</p>
                            <ul>
                                <li><a href="https://sisweb.tesouro.gov.br/apex/f?p=10250:7:111887894361883::NO:RP,7:P7_ID_PROJETO:461" target="_blank">API</a> - Usando a API de Leilões do Tesouro Nacional é possível consultar informações sobre resultados, portarias, benchmarks e dealers do mercado interno. <b>Formato recomendado</b> para a importação de dados para outros sistemas de informação. </li>
                                <li><a href="http://www.tesourotransparente.gov.br/ckan/dataset/ds013" target="_blank">Planilhas de Resultados</a> - As planilhas de resultados disponíveis no portal Tesouro Transparente permitem a consulta ao resultado dos leilões de forma geral ou por ano de oferta. Atualizadas automaticamente, são um <b>formato alternativo</b> para a importação de dados para outros sistemas de informação. </li>
                                <li><a href="https://sisweb.tesouro.gov.br/apex/f?p=2691:2" target="_blank">Consulta Online</a> - Consulta a resultados diretamente pelo sítio do Tesouro Nacional. <b>Recomendado para consultas pontuais</b>.</li>
                                <li><a href="https://sisweb.tesouro.gov.br/apex/f?p=2501:9::::9:P9_ID_COMUNICADO:1083">Leilão de Troca NTN-B</a> - Planilha com o histórico das operações de troca de títulos NTN-B.</li>

                            </ul>
                            <p>As emissões competitivas de títulos públicos têm como objetivo atender as necessidades de financiamento do governo federal, assegurando um balanço adequado entre custos e riscos para a DPF, além de favorecer o bom funcionamento do mercado de títulos públicos.</p>
                            <p>Tipicamente, os leilões ocorrem às terças-feiras, para as NTN-B e LFT, e às quintas-feiras, para as LTN e NTN-F, com divulgação das portarias às 10h30, coleta de propostas entre 11h00 e 11h30 e divulgação do resultado a partir das 11h45. </p>


                            <p>Os gráficos <b>"Taxa de Corte"</b> apresentam a maior taxa aceita pelo Tesouro Nacional para cada tipo de título nos últimos 120 dias. </p>
                            <p>Os gráficos <b>"Emissões por Título - 12 Meses"</b> e <b>"Volume Emitido - 12 Meses"</b> apresentam, em termos financeiros, o volume emitido pelo Tesouro Nacional para cada tipo de título nos últimos 12 meses. </p>
                           

                        </Card.Content>
                    </Card>
                </Col>
            </Row>


            <Row>
                <DetalheResultado />
            </Row>
        </Container>
    );
}

export default SiteLeiloes;
