import { IRegistrosCalendarioImpressao } from "../interfaces/ICalendarioImpressao";
import IComunicado, { IRegistrosComunicados } from "../pages/SiteLeiloes/Comunicados/IComunicado";
import IBenchmarkOfertado, { IBenchmarksOfertados } from "../pages/SiteLeiloes/DetalheResultado/IBenchmarkOfertado";
import ITituloIndexador, { IRegistrosTituloIndexador } from "../pages/SiteLeiloes/Graficos/EmissoesIndexacao/ITituloIndexador";
import IRegistrosTaxaCorte from "../pages/SiteLeiloes/Graficos/TaxaCorte/ITaxaCorte";
import IVolume, { IRegistrosVolume } from "../pages/SiteLeiloes/Graficos/VolumeEmitido/IVolume";
import IPortaria, { IRegistrosPortaria } from "../pages/SiteLeiloes/PortariasDia/IPortaria";
import IProximoLeilao, { IProximosLeiloes } from "../pages/SiteLeiloes/ProximosLeiloes/IProximoLeilao";
import IResultadoLeilao, { IRegistrosResultadoLeilao } from "../pages/SiteLeiloes/ResultadoLeilao/IResultadoLeilao";

export default class AriaService {
    private static _instance : AriaService = new AriaService();

    public async getPortariasDia() : Promise<IPortaria[]> {
        return new Promise<IPortaria[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/portarias-do-dia")
                .then(response => response.json() as Promise<IRegistrosPortaria>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getComunicados() : Promise<IComunicado[]> {
        return new Promise<IComunicado[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/comunicados")
                .then(response => response.json() as Promise<IRegistrosComunicados>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getTitulosIndexador() : Promise<ITituloIndexador[]> {
        return new Promise<ITituloIndexador[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/grafico/emissao_por_indexador")
                .then(response => response.json() as Promise<IRegistrosTituloIndexador>)
                .then(data => {
                    resolve(data.registros);
                });
        })
    }

    public async getVolumesBenchmark() : Promise<IVolume[]> {
        return new Promise<IVolume[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/volume-emitido-por-benchmark")
                .then(response => response.json() as Promise<IRegistrosVolume>)
                .then(data => {
                    resolve(data.registros);
                });
        })
    }

    public async getTaxasCorte(titulos: string[]) : Promise<IRegistrosTaxaCorte> {
        return new Promise<IRegistrosTaxaCorte>((resolve, reject) => {
            fetch(`https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/grafico-taxa-corte-benchmark?titulos=${titulos.join(':')}`)
                .then(response => response.json() as Promise<IRegistrosTaxaCorte>)
                .then(data => {
                    resolve(data);
                });
        })
    }   

    public async getResultadosLeilao() : Promise<IResultadoLeilao[]> {
        return new Promise<IResultadoLeilao[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/resultado-leilao")
                .then(response => response.json() as Promise<IRegistrosResultadoLeilao>)
                .then(data => {
                    resolve(data.registros);
                });
        })
    } 

    public async getProximosLeiloes() : Promise<IProximoLeilao[]> {
        return new Promise<IProximoLeilao[]>((resolve, reject) => {
            fetch("https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/proximos-leiloes")
                .then(response => response.json() as Promise<IProximosLeiloes>)
                .then(data => {
                    resolve(data.registros);
                });
        })
    } 

    public async getBenchmarksOftertados(data: string) : Promise<IBenchmarkOfertado[]> {
        return new Promise<IBenchmarkOfertado[]>((resolve, reject) => {
            
            fetch(`https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/detalhe-benchmark-ofertado?data_leilao=${data}`)
                .then(response => response.json() as Promise<IBenchmarksOfertados>)
                .then(data => {
                    resolve(data.registros);
                });
        })
    } 
    

    public async getUrlCalendarioImpressao() : Promise<string> {
        return new Promise<string>((resolve, reject) => {
            
            fetch(`https://apiapex.tesouro.gov.br/aria/v1/leiloes/custom/calendario-impressao`)
                .then(response => response.json() as Promise<IRegistrosCalendarioImpressao>)
                .then(data => {
                    resolve(data.registros[0].link);
                });
        })
    } 

    
}